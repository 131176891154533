<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div v-if="!item.id" class="p-dialog-title">Adding a new cashbox transaction</div>
      <div v-else class="p-dialog-title">Cashbox transaction editing</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="transaction_type"
                    :class="{'p-invalid' : submitted && !selectedType}"
                    v-model="selectedType"
                    :options="transactionTypes"
                    :disabled="isEditingDisabled"
                    optionLabel="label"
                    autocomplete="off"
                    :showClear="false">
            <template #value="slotProps">
              <div class="country-item country-item-value" v-if="slotProps.value">
                <span>{{ slotProps.value.label }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.label }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="transaction_type">Type<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedType">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5" v-if="selectedType?.num === 5">
        <div class="p-float-label">
          <CustomDropdown id="transaction_selected_employee"
                              :class="{'p-invalid' : submitted && !selectedEmployee}"
                              v-model="selectedEmployee"
                              @change="changeselectedEmployee"
                              :options="employees"
                              :disabled="isEditingDisabled"
                              :filter="true"
                              :filterFields="['first_name', 'last_name']"
                              autocomplete="off"
                              :showClear="false">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.first_name }} {{ slotProps.value.last_name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="transaction_selected_employee">{{ $t('Employee') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedEmployee">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5" v-if="cashboxesData?.length > 1">
        <div class="p-float-label">
          <CustomDropdown id="transaction_cashbox"
                          :class="{'p-invalid' : submitted && !selectedCashbox}"
                          v-model="selectedCashbox"
                          :options="cashboxesData"
                          :disabled="isEditingDisabled"
                          optionLabel="name"
                          autocomplete="off"
                          :showClear="false">
            <template #value="slotProps">
              <div class="country-item country-item-value" v-if="slotProps.value">
                <span>{{ slotProps.value.name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="transaction_cashbox">Cashbox<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedCashbox">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-3 p-sm-2 p-md-2 p-lg-2 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="transaction_sign"
                    :class="{'p-invalid' : submitted && !selectedSign}"
                    v-model="selectedSign"
                    :options="signs"
                    :disabled="!selectedType || !selectedCashbox || isEditingDisabled"
                    optionLabel="sign"
                    autocomplete="off"
                    :showClear="false">
            <template #value="slotProps">
              <div class="country-item country-item-value" v-if="slotProps.value">
<!--                <span>{{ slotProps.value.sign }}</span>-->
                <i :class="slotProps.value.icon"></i>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <i :class="slotProps.option.icon"></i>
<!--                <span>{{ slotProps.option.sign }}</span>-->
              </div>
            </template>
          </CustomDropdown>
<!--          <InputNumber :locale="computedNumberInputLocale" :locale="computedNumberInputLocale" id="transaction_sum"  v-model="itemData.sum" :useGrouping="false" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"></InputNumber>-->
<!--          <label for="transaction_sign">Sign<span class="warning-color">*</span></label>-->
        </div>
      </div>

      <div class="p-field p-col-9 p-sm-10 p-md-10 p-lg-10 p-mb-5">
        <div class="p-float-label">
          <InputNumber :locale="computedNumberInputLocale" :disabled="!selectedType || !selectedCashbox || isEditingDisabled" id="transaction_sum" :inputClass="{'p-invalid' : submitted && !itemData.sum}" v-model="itemData.sum" :min="0" :useGrouping="false" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"></InputNumber>
          <label for="transaction_sum">Sum<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.sum">{{ $t('Required field') }}</small>
      </div>

<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5"-->
<!--           v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin">-->
<!--        <div class="p-float-label">-->
<!--          <Calendar id="transaction_date"-->
<!--                    :showButtonBar="true"-->
<!--                    :class="{'p-invalid' : submitted && !itemData.date}"-->
<!--                    v-model="itemData.date"-->
<!--                    :showTime="true"-->
<!--                    :stepMinute="10"-->
<!--                    :dateFormat="settings.dateFormat"-->
<!--                    :showIcon="true"-->
<!--                    autocomplete="off"/>-->
<!--          <label for="transaction_date">Date<span class="warning-color">*</span></label>-->
<!--        </div>-->
<!--        <small class="p-invalid" v-if="submitted && !itemData.date">{{ $t('Required field') }}</small>-->
<!--      </div>-->

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <i class="ti-comment-alt" />-->
<!--          <Textarea :disabled="!selectedType || !selectedCashbox" id="transaction_description" :class="{'p-invalid' : submitted && !itemData.description}" v-model="itemData.description" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="transaction_description">Description<span class="warning-color">*</span></label>-->
<!--        </div>-->
<!--        <small class="p-invalid" v-if="submitted && !itemData.description">{{ $t('Required field') }}</small>-->
        <LimitedCharsTextarea :submitted="submitted"
                              v-model="itemData.description"
                              :maxChars="commentMaxChars"
                              :required="true"
                              :id="'cashbox-transaction-description'"
                              :label="$t('Description')"/>
      </div>

    </div>


    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import CashboxTransactionTypes from '@/translations/CashboxTransactionTypes'
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";
import constants from "@/constants";
import formatMixins from "@/mixins/formatMixins";

export default {
  mixins: [ httpMixins, formatMixins ],
  emits: ['close', 'update-items'],
  name: 'CashboxModal',
  props: {
    item: Object,
    visible: Boolean,
    cashboxes: Array,
    employees: [Array, null],
    selectedCashboxId: [Number, null],
  },
  data() {
    return {
      commentMaxChars: 100,
      dataIsSending: false,
      disableSaveButton: false,
      // showEmployeesDropdown: false,
      isEditingDisabled: false,
      selectedEmployee: null,
      // employees: [],
      itemData: {},
      cashboxesData: [],
      selectedCashbox: null,
      selectedSign: null,
      selectedType: null,
      submitted: false,
      transactionTypes: null,
      signs: [{ sign: '-',icon: 'ti-minus' }, { sign: '+', icon: 'ti-plus' }],
      settings,
      constants
    }
  },
  watch: {
    item(value) {
      this.transactionTypes = CashboxTransactionTypes[this.$i18n.locale].filter(transaction => transaction.num !== 1)

      if (this.cashboxes && this.cashboxes.length) {
        this.cashboxesData = this.cashboxes.filter(cashbox => cashbox.id)
      }

      this.itemData = {
        id: value.id ?? null,
        type: value.type ?? null,
        description: value.description ?? null,
      }

      if (value.id) {
        this.isEditingDisabled = true
      }

      // if (value.date) {
      //   this.itemData.date = new Date(value.date * 1000)
      // } else {
      //   this.itemData.date = new Date()
      // }

      if (value.sum || +value.sum === 0) {
        if (value.sum < 0) {
          this.itemData.sum = +value.sum.split('-')[1]
        } else if (value.sum === 0) {
          this.itemData.sum = 0
        } else if (value.sum > 0) {
          this.itemData.sum = +value.sum
        }
      }

      if (!value.id) {
        this.selectedSign = this.signs[0]
      } else {
        if (value.sum < 0) {
          this.selectedSign = this.signs[0]
        } else {
          this.selectedSign = this.signs[1]
        }
      }

      if (value.type) {
        this.selectedType = this.transactionTypes.find(type => type.num === value.type)
        // if (value.type === 5) {
        //   this.showEmployeesDropdown = true
        // }
      }

      if (value.employee) {
        this.selectedEmployee = value.employee
      }

      if (value.cashbox_id && this.cashboxesData?.length) {
        this.selectedCashbox = this.cashboxesData.find(cashbox => cashbox.id === value.cashbox_id)
      } else if (this.selectedCashboxId) {
        this.selectedCashbox = this.cashboxesData.find(cashbox => cashbox.id === this.selectedCashboxId)
      }

    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.selectedSign = null
        this.selectedCashbox = null
        this.selectedType = null
        this.selectedEmployee = null
        this.isEditingDisabled = false
        // this.showEmployeesDropdown = false
      }
    },
  },
  methods: {
    changeselectedEmployee() {
      if (this.selectedEmployee) {
        this.itemData.description = `Payment: ${this.selectedEmployee.first_name} ${this.selectedEmployee.last_name}`
      } else {
        this.description = ''
      }
    },
    changeSelectedType() {
      if (this.selectedType?.num === 5) {
        // this.description +=
        // this.showEmployeesDropdown = true
      } else {
        // this.showEmployeesDropdown = false
      }
    },
    async saveItem() {
      this.submitted = true
      if (!this.itemData.sum ||
          !this.itemData.description ||
          !this.selectedCashbox ||
          !this.selectedType ||
          !this.selectedSign ||
          (this.selectedType?.num === 5 && !this.selectedEmployee) ||
          this.itemData.description && this.itemData.description.length > this.commentMaxChars) {
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const transaction = {
        type: this.selectedType ? this.selectedType.num : null,
        cashboxId: this.selectedCashbox ? this.selectedCashbox.id : null,
        description: this.itemData.description ? this.itemData.description : null,
        userId: this.selectedType?.num === 5 && this.selectedEmployee ? this.selectedEmployee.id : null,
        // date: +new Date(this.itemData.date / 1000),
      }

      if ((this.selectedSign.sign === '-') && this.itemData.sum) {
        transaction.sum = -this.itemData.sum
      } else if ((this.selectedSign.sign === '+') && this.itemData.sum) {
        transaction.sum = this.itemData.sum
      }

      if (this.itemData.id) {
        transaction.id = this.itemData.id
        try {
          const { status, data } = await httpClient.post(`cashbox-transaction/update`, transaction)
          if (data?.errors) console.log(data.errors)
          if (status === 200 && data.success) {
            this.$emit('update-items', this.itemData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
            this.close()
          } else if (data.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`cashbox-transaction/create`, transaction)
          if (status === 201 && data?.id && data.success) {
            this.itemData.id = data.id
            transaction.id = data.id
            this.itemData.isNew = true
            this.$emit('update-items', this.itemData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
            this.close()
          } else if (data.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    // computedWindowSize() {
    //   if (this.$store.state.mobileLayout) {
    //     return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
    //   } else {
    //     return {position: 'absolute', top: 0, width: '550px'}
    //   }
    // },
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '95%', maxWidth: '460px', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '460px', marginTop: '25px'}
      } else {
        return {position: 'absolute', top: 0, maxWidth: '460px', marginTop: '50px'}
      }
    }
  },

}
</script>

<style scoped lang="scss">

</style>