<template>
  <div class="p-d-flex p-flex-column">
<!--    :class="{'p-invalid' : !datesRangeIsValid}"-->
    <Calendar v-model="datesRange"
              @date-select="changeDatesRange"
              @input="changeDatesRange"
              style="min-width: 220px;"
              @clear-click="clearDates"
              :disabled="disabled"
              selectionMode="range"
              :dateFormat="settings.dateFormat"
              :showIcon="true"
              :monthNavigator="true"
              :yearNavigator="true"
              :showButtonBar="true"
              :selectOtherMonths="true"
              :minDate="minDate"
              :maxDate="new Date()"
              autocomplete="off"/>
<!--    <small class="p-invalid" v-if="!datesRangeIsValid">{{ $t('Invalid date format') }}</small>-->
  </div>
</template>

<script>
import settings from "@/settings";
import generateMixins from "@/mixins/generateMixins";
import calculateMixins from "@/mixins/calculateMixins";
export default {
  emits: ['change-dates-range'],
  mixins: [ generateMixins, calculateMixins ],
  name: 'DatesRangeCalendar',
  props: {
    isEmptyByDefault: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: [Date,String,Boolean],
      default: null
    }
  },
  data() {
    return {
      datesRange: null,
      fromTimestamp: null,
      toTimestamp: null,
      // datesRangeIsValid: true,
      toDateIsValid: false,
      settings
    }
  },
  mounted() {
    if (!this.isEmptyByDefault) {
      this.generateThisMonthRange()
    }
  },
  methods: {
    clearDates() {
      this.changeDatesRange()
    },
    // checkDatesAreValid() {
    //   const fromDate = this.datesRange?.[0] ?? null
    //   const toDate = this.datesRange?.[1] ?? null
    //
    //   const fromDateIsValid = fromDate ? this.checkDateIsValid(fromDate) : false
    //   const toDateIsValid = toDate ? this.checkDateIsValid(toDate) : false
    //
    //   if (fromDate && toDate && (!fromDateIsValid || !toDateIsValid)) {
    //     this.datesRangeIsValid = false
    //   } else {
    //     this.datesRangeIsValid = true
    //   }
    // },
    checkDateIsValid(date) {
      if (!date) return false
      return !!(date instanceof Date && !isNaN(date))
    },
    generateThisMonthRange() {
      // console.log('generateThisMonthRange')
      let now = new Date()
      let firstDayOfCurrMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 1)

      const calculatedFirstDayOfCurrMonth =  firstDayOfCurrMonth ? this.calculateDayStartDate(firstDayOfCurrMonth) : null
      const toDate = this.calculateDayEndDate(now)


      this.datesRange = [calculatedFirstDayOfCurrMonth, toDate]
      this.fromTimestamp = calculatedFirstDayOfCurrMonth ? +calculatedFirstDayOfCurrMonth / 1000 : null
      this.toTimestamp = toDate ? +toDate / 1000 : null

      // this.checkDatesAreValid()
    },
    // changeDatesRangeOnBlur() {
    //   this.datesRange = null
    //   this.changeDatesRange()
    // },
    changeDatesRange() {
      // console.log(this.datesRange)
      // if (!this.datesRange || !this.datesRange[0] || !this.datesRange[1]) return false
      const fromDate = this.datesRange?.[0] ?? null
      const toDate = this.datesRange?.[1] ?? null

      // const calculatedToDate =  toDate ? this.calculateToDate(toDate) : null
      const calculatedFromDate =  fromDate ? this.calculateDayStartDate(fromDate) : null
      const calculatedToDate =  toDate ? this.calculateDayEndDate(toDate) : null

      this.fromTimestamp = calculatedFromDate ? +calculatedFromDate / 1000 : null
      this.toTimestamp = calculatedToDate ? +calculatedToDate / 1000 : null

      // this.checkDatesAreValid()
      // if ((this.fromTimestamp && this.toTimestamp && this.datesRangeIsValid) ||
      if ((this.fromTimestamp && this.toTimestamp) ||
          (!this.fromTimestamp && !this.toTimestamp)) {
        this.$emit('change-dates-range', { fromTimestamp: this.fromTimestamp, toTimestamp: this.toTimestamp }, true)
      }
    },
    // calculateToDate(toTimestamp) {
    //   if (!toTimestamp) return false
    //   // console.log(+toTimestamp >  +new Date())
    //
    //   // const toDate = +toTimestamp > new Date(+new Date()) ? new Date() : new Date(toTimestamp)
    //   const toDate = new Date(toTimestamp)
    //   // const toDate = new Date(toTimestamp)
    //   // console.log(toDate)
    //   const year = toDate.getFullYear()
    //   const month = toDate.getMonth()
    //   const date = toDate.getDate() + 1
    //   const hours = toDate.getHours()
    //   const seconds = toDate.getSeconds() - 1
    //   const milliseconds = toDate.getMilliseconds() - 1
    //
    //   // if (+toTimestamp > +new Date())
    //
    //   // console.log(new Date(year, month, date, hours, seconds, milliseconds))
    //   // getHours(), getMinutes(), getSeconds(), getMilliseconds()
    //   return new Date(year, month, date, hours, seconds, milliseconds)
    // },
  }
}
</script>

<style scoped>

</style>